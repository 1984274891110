<template>
	<div id="pubfooter">
		<div class="Ft_logo">
			<img src="@/assets/images/985/home/logo.png" alt="">
		</div>
		<!-- <div class="Ft_pic">
			<img src="@/assets/images/public/Ftimg.png" alt="">
		</div> -->
		<div class="Ft_btn">
			<p @click="RouteJump('/rule')">提货规则</p>
			<p @click="RouteJump('/agreement')">用户协议</p>
			<p @click="RouteJump('/Privacy')">隐私协议</p>
			<p @click="RouteJump('/Chance')">概率公平性</p>
		</div>
		<!-- <a href="https://beian.miit.gov.cn/">湘ICP备2023025550号-1</a> -->
		<!-- <div class="copyright">版权所有 ©2020-2021 985csgo.com All Rights Reserved</div> -->
	</div>
</template>

<script>
	import { ApiInfo } from '@/network/api.js'
	export default{
		name:'pubfooter',
		data(){
			// return{
				
			// }
		},
		
		created() {
			this.GetApiInfo()
		},
		
		methods:{
			//查看规则
			RouteJump(str) {
				if (str == this.$route.fullPath) {
					this.$router.go(0)
				} else {
					this.$router.push(str)
					this.NavShow = false
					this.PerNavShow = false
				}
			},
			GetApiInfo(){
				ApiInfo().then((res) => {
					// console.log(res.data.data.static_file_url)
					localStorage.setItem('static_file_url',res.data.data.static_file_url)
				})
			}
		}
	}
</script>

<style lang="scss">
	#pubfooter{
		width: 100%;
		height: 180px;
		border-top: 1px solid #090b10;
		padding: 10px 0 20px;
		background-color: #333;
		// background: url(../../assets/images/Header/Header_Back.jpg) no-repeat center;
		background-size: cover;
		box-sizing: border-box;
		.Ft_logo{
			width: 80%;
			margin: 0 auto;
			text-align: center;
			img{
				width: 50%;
			}
		}
		.Ft_pic{
			width: 100%;
			margin: 0 auto 10px;
			text-align: center;
			img{
				width: 80%;
			}
		}
		.Ft_btn{
			width: 100%;
			display: flex;
			p{
				flex: 1;
				color: #fff;
				text-align: center;
				font-size: 12px;
			}
		}
		a{
			display: block;
			text-align: center;
			font-size: 12px;
			color: #acacac;
			margin-top: 10px;
		}
		.copyright{
			width: 100%;
			text-align: center;
			color: #acacac;
			margin-top: 10px;
			font-size: 10px;
		}
	}
</style>
