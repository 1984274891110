<template>
  <div id="Index">
    <div class="SwiperBox-text">
			<!-- <van-notice-bar mode="closeable"  scrollable
			style="margin: -.1rem 0;font-size:16px;" background="none"
			color="#fff" left-icon="volume-o">{{site_bulletin}}</van-notice-bar> -->
		</div>
    <pub-header></pub-header>
    <router-view class="Router_View" />
    <back-top></back-top>
    <broad-side></broad-side>
    <pub-footer></pub-footer>
  </div>
</template>

<script>

import PubHeader from "@/components/PubHeader/index.vue";
import PubFooter from "@/components/PubFooter/index.vue";
import BackTop from "@/components/BackTop/index.vue"; //回到顶部
import BroadSide from "@/components/BroadSide/index.vue"; //红包
import { ApiInfo } from '@/network/api.js'
export default {
  name: "Index",
  data() {
    return {
      site_bulletin:''
    };
  },
  components: {
    PubHeader,
    PubFooter,
    BackTop,
    BroadSide,
  },
  created(){
    this.GetInfo()
  },
 methods:{
    GetInfo(){
      ApiInfo().then((res) => {
        // console.log(res.data.data)
        this.site_bulletin = res.data.data.site_bulletin
      })
    }
 }
};
</script>

<style lang="scss">
#Index:before {
  content: ' ';
  position: fixed;
}
#Index::-webkit-scrollbar { width: 0 !important }
#Index {
  // background:#101923;
  // background-image: url('../../assets/images/q2/home/homebac.png');
  background: #1d0f3d;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: auto;
   background-position: top;
  background-size: 100% 100%;
  width: 390px;

  .SwiperBox-text{
			// background: url(https://181skins.com/assets/banner_bg.575e8c84.png) center center no-repeat;
			width: 100% 100%;
			background-color: none !important;
      margin-top: 50px !important;
      font-size: 18px !important;
		}
 
  .Router_View {
    min-height: calc(100vh - 334px);
    .Title {
      p {
        font-size: 12px;
      }
      // background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAATUAAAAvCAYAAACCJV1NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA4RpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpmYWUzNGE1ZC03MGI5LWRmNDQtYTk2Ny1iODBiMGY3YTE4NmEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6N0UwMzg2QkQ2OTUyMTFFRDkzN0ZGQ0FFRjM2MzNEREYiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6N0UwMzg2QkM2OTUyMTFFRDkzN0ZGQ0FFRjM2MzNEREYiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTI0NTY3NzYtNmU2Yy1lZTRhLTlhNTYtOWI2MWVkNTRlNjFkIiBzdFJlZjpkb2N1bWVudElEPSJhZG9iZTpkb2NpZDpwaG90b3Nob3A6MGVhNDFlNjQtODg5Zi04NzQzLWEzNWMtYmQ1Zjc1MmQ0NzllIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+GapSaQAAAOJJREFUeNrs3LEJgDAQQFHjKg7gDE7uDA7gLLEKWChY5NSE9yqrIBafs7iknPNQ0zrN5bHuwUBP0rJvIQePvi3QE1EDRA1A1ABEDUDUAFEDEDUAUQMQNQBRA0QNQNQARA0gRKp99VCxTrOrh4BLy74lkxrAw0mtiYnqdPkk8M101cR7mtSArogaIGoAogYgagCiBogagKgBiBpAJLufwOsidz+rR+20ziRqwG17otau/H4CXRE1QNQARA1A1ABEDRA1AFEDEDUAUQMQNUDUAEQNQNQARA1A1ABRAxA1gB84BBgAUKMjSrZK4y0AAAAASUVORK5CYII=)
      //   no-repeat center;
    }
    .Title::after {
      background: none;
    }
    .Title::before {
      background: none;
    }
  }
}

</style>
